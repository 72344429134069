import React from 'react';
import { BsWhatsapp, BsInstagram, BsGithub } from 'react-icons/bs';


const SocialMedia = () => (
  <div className="app__social">
    <div>
      <BsWhatsapp />
    </div>
    <div>
      <a href='github.com'><BsGithub /></a>
    </div>
    <div>
      <BsInstagram />
    </div>
  </div>
);

export default SocialMedia;